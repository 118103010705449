"use client";

import Link, { type LinkProps } from "next/link";
import Image from "next/image";
import { useContext } from "react";
import { twMerge } from "tailwind-merge";
import { ScrollArea } from "@/components/scroll-area";
import { ActivitySidebarContext } from "../../provider";

export function ActivityList({ children }: React.PropsWithChildren) {
  return (
    <div className="relative flex-1">
      <div className="absolute inset-0 flex h-full min-w-full">
        <ScrollArea className="top-0 max-w-full flex-1 overflow-hidden">
          <div className="items-center justify-center ">
            <div className="grid p-2">{children}</div>
          </div>
        </ScrollArea>
      </div>
    </div>
  );
}

ActivityList.Item = Item;

function Item(props: LinkProps) {
  const { open } = useContext(ActivitySidebarContext);
  return (
    <Link
      {...props}
      className={twMerge(
        "flex h-16 items-center gap-2 font-diablo [&:not(:last-child)]:mb-2",
      )}
      scroll={false}
      style={{
        borderImage:
          "url('/images/borders/activity-border-legendary.png') 10 stretch",
        borderImageWidth: "auto",
      }}
    >
      <div
        className={twMerge(
          "relative",
          !open && "flex w-16 items-center justify-center",
        )}
      >
        <div className="relative h-[48px] w-[42px]">
          <Image alt="" fill src="/ax.png" />
        </div>
        {!open && (
          <div className="absolute right-2 top-0 mb-1 ml-auto flex h-4 w-4 items-center justify-center rounded-full bg-red-300 text-xs">
            1
          </div>
        )}
      </div>
      <div
        className={twMerge(
          "flex-1 text-left text-sm text-orange-200",
          !open ? "sr-only 1536:not-sr-only" : "1536:not-sr-only",
        )}
      >
        Ancestral Legendary Two-Handed Axe
      </div>
      <div
        className={twMerge(
          "pr-4",
          !open
            ? "sr-only 1536:not-sr-only 1536:pr-4"
            : "1536:not-sr-only 1536:pr-4",
        )}
      >
        <div className="mb-1 ml-auto  flex h-4 w-4 items-center justify-center rounded-full bg-red-300  text-xs">
          1
        </div>
        <div className="text-xs">2hr 21min Left</div>
      </div>
    </Link>
  );
}
