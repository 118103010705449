"use client";

import { XIcon } from "lucide-react";
import { twMerge } from "tailwind-merge";

export function Middle({ children }: React.PropsWithChildren): JSX.Element {
  return (
    <div className="w-[820px] overflow-hidden rounded-md bg-gray-900">
      {children}
    </div>
  );
}

Middle.Header = Header;
Middle.Content = Content;
Middle.Title = Title;
Middle.CloseBtn = CloseBtn;

function Header({ children }: React.PropsWithChildren): JSX.Element {
  return (
    <div className="relative mb-4 grid h-10  items-center">{children}</div>
  );
}

function Content({
  children,
  className,
}: React.HTMLAttributes<HTMLDivElement>): JSX.Element {
  return <div className={twMerge("px-2", className)}>{children}</div>;
}

function Title({ children }: React.PropsWithChildren) {
  return (
    <h2 className="pr-8 text-center font-diablo font-bold uppercase">
      {children}
    </h2>
  );
}

function CloseBtn(props: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      {...props}
      className="absolute right-2 top-2 flex h-5 w-5 items-center justify-center rounded-full border border-red-300 text-red-300"
      type="button"
    >
      <XIcon className="h-3 w-3 text-gray-50" />
    </button>
  );
}
