"use client";

import { XCircle } from "lucide-react";

export function AuctionSidebar({
  children,
}: React.PropsWithChildren): JSX.Element {
  return <div className="rounded-md bg-gray-900 p-2">{children}</div>;
}

AuctionSidebar.Header = Header;
AuctionSidebar.Content = Content;
AuctionSidebar.Title = Title;
AuctionSidebar.CloseBtn = CloseBtn;

function Header({ children }: React.PropsWithChildren) {
  return (
    <div className="mb-4 flex items-center justify-between font-diablo text-xs uppercase">
      {children}
    </div>
  );
}

function Content({ children }: React.PropsWithChildren) {
  return <div>{children}</div>;
}

function Title() {
  return (
    <h3 className="mb-4 flex items-center justify-between font-diablo text-xs uppercase">
      Auction Information
    </h3>
  );
}

function CloseBtn(props: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button {...props} type="button">
      <XCircle className="h-6 w-6 stroke-1" />
    </button>
  );
}
