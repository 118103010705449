"use client";

import { twMerge } from "tailwind-merge";
import { useState, useContext } from "react";
import {
  MoveLeft,
  MoveRight,
  ChevronDown,
  ArrowDownNarrowWide,
} from "lucide-react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectIcon,
  SelectTrigger,
  SelectValue,
} from "@/components/select";

import { MoneyBagIcon } from "@/components/icons/money-bag";
import { ActivityList } from "./components/acttivy-list";
import { ActivitySidebarContext } from "./provider";

export function ActivitySidebar({
  children,
}: React.PropsWithChildren): JSX.Element {
  const [open, setOpen] = useState<boolean>(false);

  const value = {
    open,
    setOpen,
  };

  return (
    <ActivitySidebarContext.Provider value={value}>
      <aside className="grid">
        <div className="relative z-20">
          <div
            className={twMerge(
              "absolute inset-0 z-30 flex w-80 flex-col overflow-hidden rounded-md  bg-gray-900 shadow-lg shadow-background",
              open ? "w-80 1536:w-80" : "w-20 1536:w-80",
            )}
          >
            <Header />
            <div className="flex flex-1 flex-col">
              <div
                className={twMerge(
                  "mb-4 grid grid-cols-2 gap-2 px-2",
                  !open && "hidden 1536:grid",
                )}
              >
                {/** ACTIVITY FILTERS */}
                <Select>
                  <SelectTrigger>
                    <SelectValue className="bg-primary" placeholder="Select" />
                    <SelectIcon asChild>
                      <ChevronDown className="h-5 w-5 opacity-80" />
                    </SelectIcon>
                  </SelectTrigger>
                  <SelectContent className="text-xs">
                    <SelectGroup>
                      {Array.from({ length: 4 }).map((_, i) => {
                        return (
                          <SelectItem
                            className="font-diablo text-base"
                            key={i}
                            value={String(i)}
                          >
                            Option {i}
                          </SelectItem>
                        );
                      })}
                    </SelectGroup>
                  </SelectContent>
                </Select>
                <Select>
                  <SelectTrigger>
                    <SelectValue placeholder="Select" />
                    <SelectIcon asChild>
                      <ArrowDownNarrowWide className="h-5 w-5 opacity-80" />
                    </SelectIcon>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {Array.from({ length: 4 }).map((_, i) => {
                        return (
                          <SelectItem
                            className="font-diablo text-base"
                            key={i}
                            value={String(i)}
                          >
                            Option {i}
                          </SelectItem>
                        );
                      })}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>

              {children}
            </div>
          </div>
        </div>
      </aside>
    </ActivitySidebarContext.Provider>
  );
}

ActivitySidebar.ActivityList = ActivityList;

function Header() {
  const { open, setOpen } = useContext(ActivitySidebarContext);

  return (
    <div className="mb-4 flex h-10 items-center px-2">
      <h2 className="flex items-center uppercase">
        <div>
          <MoneyBagIcon className="mr-2 h-5 w-5" />
        </div>
        <div
          className={twMerge(
            "whitespace-nowrap font-diablo text-base",
            !open && "sr-only  1536:not-sr-only",
          )}
        >
          Trading activity
        </div>
      </h2>
      <div className="ml-auto flex">
        <button
          className={twMerge("1536:hidden", !open && "hidden")}
          onClick={() => {
            setOpen(false);
          }}
          type="button"
        >
          <MoveLeft className="ml-auto h-5 w-5 font-medium" />
        </button>

        <button
          className={twMerge("1536:hidden", open && "hidden")}
          onClick={() => {
            setOpen(true);
          }}
          type="button"
        >
          <MoveRight className="ml-auto h-5 w-5 font-medium" />
        </button>
      </div>
    </div>
  );
}
