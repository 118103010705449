import { createContext, type Dispatch, type SetStateAction } from "react";

export interface ActivitySidebarContextType {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const ActivitySidebarContext = createContext(
  {} as ActivitySidebarContextType,
);
