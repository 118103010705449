import { Header } from "./components/header";
import { Middle } from "./components/middle";
import { ActivitySidebar } from "./components/activity-sidebar";
import { AuctionSidebar } from "./components/auction-sidebar";

export function Layout({ children }: React.PropsWithChildren): JSX.Element {
  return <div className="hidden xl:block">{children}</div>;
}

Layout.Head = Head;
Layout.Header = Header;

Layout.Body = Body;

Layout.Left = Left;
Layout.LeftAd = LeftAd;
Layout.LeftSidebar = LeftSidebar;
Layout.Middle = Middle;

Layout.Right = Right;
Layout.RightAd = RightAd;
Layout.RightSidebar = RightSidebar;

Layout.ActivitySidebar = ActivitySidebar;
Layout.AuctionSidebar = AuctionSidebar;

function Head({ children }: React.PropsWithChildren): JSX.Element {
  return <div className="h-20 overflow-hidden">{children}</div>;
}

function Body({ children }: React.PropsWithChildren): JSX.Element {
  return (
    <div className="mt-2 grid grid-cols-[auto_auto_auto] justify-center">
      {children}
    </div>
  );
}

function Left({ children }: React.PropsWithChildren): JSX.Element {
  return (
    <div className="sticky top-0 z-20 flex h-[calc(100vh-5.5rem)]">
      {children}
    </div>
  );
}

function LeftAd({ children }: React.PropsWithChildren): JSX.Element {
  return <div className="mr-2 hidden w-40 1812:grid ">{children}</div>;
}

function LeftSidebar({ children }: React.PropsWithChildren): JSX.Element {
  return <div className="mr-2 grid w-20 2xl:w-80">{children}</div>;
}

function Right({ children }: React.PropsWithChildren): JSX.Element {
  return (
    <div className="sticky top-0 flex  h-[calc(100vh-5.5rem)]">{children}</div>
  );
}

function RightAd({ children }: React.PropsWithChildren): JSX.Element {
  return (
    <div className="ml-2 hidden h-[calc(100vh-5rem)] w-40 1812:grid">
      {children}
    </div>
  );
}

function RightSidebar({ children }: React.PropsWithChildren): JSX.Element {
  return <div className="ml-2 grid w-[320px] overflow-hidden">{children}</div>;
}
